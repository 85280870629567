%button {
  @include button;

  &:hover {
    @include button-hovered;
  }

  &:focus {
    @include button-focused;
  }

  &:focus:not(:focus-visible) {
    outline-style: none;
  }

  &:active {
    @include button-active;
  }

  &[disabled], &.leaflet-disabled {
    @include button-disabled;
  }

  &[aria-pressed=true], &[aria-expanded=true] {
    @include button-pressed;
  }
}

%square-button {
  @extend %button;
  height: var(--ui-button-size);
  width: var(--ui-button-size);
  line-height: calc(var(--ui-button-size) - 1.2rem);
}

%panel {
  background-color: var(--ui-background);
  color: var(--ui-text);
  border-radius: var(--ui-border-radius);
  border: var(--ui-border);
  padding: 0.2rem 0.5rem;
}
