@mixin button {
  display: inline-block;
  appearance: none;
  box-shadow: var(--ui-box-shadow);
  white-space: nowrap;
  background-color: var(--ui-background);
  color: var(--ui-text);
  border-radius: var(--ui-border-radius);
  border: var(--ui-border);
  background-clip: padding-box;
  position: relative;
  padding: 0.5rem;
  line-height: 2rem;
  cursor: pointer;
  transition: 0.2s all ease-in;

  > a:only-child {
    display: block;
    width: 100%;
    height: 100%;
  }

  > img:only-child,
  > svg:only-child {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: calc(100% - 1.6rem);
    height: calc(100% - 1.6rem);
    pointer-events: none;
  }

  &:visited, &:link {
    color: var(--ui-text);
  }
}


@mixin button-hovered {
  background-color: var(--ui-background-hover);
  color: var(--ui-text-hover);
}

@mixin button-pressed {
  background-color: var(--ui-background-selected);
  color: var(--ui-text-selected);
  font-weight: bold;
}

@mixin button-focused {
  outline: var(--ui-outline);
}

@mixin button-active {
  background-color: var(--ui-background-active);
  color: var(--ui-text-active);
}

@mixin button-disabled {
  background-color: var(--ui-background-disabled);
  color: var(--ui-text-disabled);
  cursor: not-allowed;

  &:hover, &:active, &:focus {
    background-color: var(--ui-background-disabled);
    color: var(--ui-text-disabled);
  }
}
