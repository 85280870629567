/* Overrides for leaflet styles */

/* Use CSS grid for control containers to avoid dealing with margins between controls */
.leaflet-control-container {
  font-size: 1.6rem;
  width: 100vw;
  height: 100vh;
  padding: var(--ui-element-spacing);
  display: grid;
  grid-auto-rows: 1fr;
  pointer-events: none;
}

.leaflet-control-container-top,
.leaflet-control-container-bottom {
  display: grid;
  grid-gap: inherit;
  grid-auto-flow: column;
  grid-auto-columns: minmax(0, 1fr);
  align-items: flex-start;
  position: relative;
  z-index: 1002;
}

.leaflet-control-container-bottom {
  align-items: flex-end;
  z-index: 1001;
}

.leaflet-top, .leaflet-left,
.leaflet-bottom, .leaflet-right {
  position: static;
  display: grid;
  grid-auto-flow: row;
  grid-gap: var(--ui-element-spacing);
  justify-content: flex-start;
  pointer-events: auto;

  .leaflet-control {
    margin: 0;
  }
}

.leaflet-bottom {
  align-content: flex-end;
}

.leaflet-center {
  justify-content: center;
}

.leaflet-right {
  justify-content: flex-end;
}

.leaflet-right .leaflet-control {
  margin-left: auto;
  margin-right: 0;
}

/* Use Pl3xmap styling for default leaflet controls */
.leaflet-control-attribution {
  font-size: 1.1rem;
  border-radius: 3px;
}

.leaflet-control-attribution a,
.leaflet-control-attribution a:visited,
.leaflet-control-attribution a:active,
.leaflet-control-attribution a:hover {
  color: black;
  text-decoration: none;
}

.leaflet-popup,
.leaflet-tooltip {
  font-size: 12px;
}

.leaflet-bar,
.leaflet-touch .leaflet-bar {
  border-radius: 0;
  border: none;
}

.leaflet-bar a,
.leaflet-touch .leaflet-bar a {
  @extend %square-button;
  display: block;
  border: var(--ui-border);
  border-top: none;
  border-radius: 0;
}

.leaflet-bar a:first-child,
.leaflet-touch .leaflet-bar a:first-child {
  border-radius: var(--ui-border-radius) var(--ui-border-radius) 0 0;
  border-top: var(--ui-border);
}

.leaflet-bar a:last-child,
.leaflet-touch .leaflet-bar a:last-child {
  border-radius: 0 0 var(--ui-border-radius) var(--ui-border-radius);
}

.leaflet-touch .leaflet-control-layers,
.leaflet-control-layers {
  background: none;
  border: none;
  border-radius: 0;
  position: relative;

  &.leaflet-control-layers-expanded {
    padding: 0;

    .leaflet-control-layers-list {
      @extend %panel;
      position: absolute;
      left: 100%;
      top: 0;
      margin-left: var(--ui-element-spacing);
      width: max-content;
      max-width: 30rem;
    }
  }

  .leaflet-control-layers-toggle {
    @extend %square-button;
    border-radius: var(--ui-border-radius);
    border: var(--ui-border);
    background-image: none;
  }

  .leaflet-control-layers-separator {
    margin: 0.5rem 0;
    border: var(--ui-border);
  }

  .leaflet-control-layers-selector {
    position: static;
    margin: 0;
    font-size: inherit;
    display: flex;
    align-items: center;
    border-radius: var(--ui-border-radius);

    span {
      margin-left: 0.5rem;
    }

    & + .leaflet-control-layers-selector {
      margin-top: 0.5rem;
    }
  }
}
