#sidebar {
  position: relative;
  transition: 0.2s transform ease-in;
  transform: none;

  &.sidebar--expanded {
    --transform: calc(-1 * var(--ui-sidebar-width));
    transform: translateX(var(--transform));
  }

  #sidebar__buttons {
    display: grid;
    grid-gap: var(--ui-element-spacing);

    button {
      @extend %square-button;
    }
  }

  #sidebar__content {
    @extend %panel;
    padding: 1rem 1.5rem;
    border-radius: 0;
    position: absolute;
    top: calc(-1 * var(--ui-element-spacing));
    left: calc(var(--ui-button-size) + var(--ui-element-spacing));
    height: 100vh;
    width: var(--ui-sidebar-width);
    transition: 0.5s transform ease-in;

    h2 {
      margin-top: 0;
    }
  }
}
